const initialState = {
  username: '',
  recaptchaV3SiteKey: '',
  recaptchaV3Required: false,
  recaptchaV2Required: false,
  recaptchaV2SiteKey: '',
  isGoogleSSOEnabled: false
}

export default (state = initialState) => state
