const recaptchaV2Listener = () => {
  const maxRetryCount = 5
  let currentRetryCount = 0
  const waitForRecaptchaFrame = setInterval( () => {
    if(currentRetryCount++ > maxRetryCount) {
      clearInterval(waitForRecaptchaFrame);
    }
    const recaptchaFrame = Array.from(document.getElementsByTagName('iframe')).find(x =>
      x.src.includes('google.com/recaptcha/enterprise/bframe'))
    if(recaptchaFrame) {
      const recaptchaWindow = recaptchaFrame.parentNode
      if (recaptchaWindow) {
        clearInterval(waitForRecaptchaFrame);
        recaptchaWindow.previousSibling.style['pointer-events'] = 'none'
      }
    }
  }, 1000)
}

const recaptchaSubmit = async (recaptchaV2Required,recaptchaRef,recaptchaV3Required,action,setCaptchaToken,executeRecaptcha) => {
  // recaptcha v2
  if (recaptchaV2Required) {
    await recaptchaV2Submit(recaptchaRef)
  }
  // recaptcha v3
  if (recaptchaV3Required) {
    await recaptchaV3Submit(action,setCaptchaToken,executeRecaptcha)
  }
}

const recaptchaV2Submit= async (recaptchaRef) => {
  recaptchaV2Listener()
  await recaptchaRef.current.executeAsync()
  document.getElementById('g-recaptcha-response').value = recaptchaRef.current ? recaptchaRef.current.getValue() : null
}

const recaptchaV3Submit= async (action,setCaptchaToken,executeRecaptcha) => {
  if (!executeRecaptcha) {
    console.log('recaptchaV3 not ready ')
    return
  }
  const result = await executeRecaptcha(action)
  setCaptchaToken(result)
}

export {
  recaptchaSubmit
}
