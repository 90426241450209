import { findMerchantPortalRegion } from "./merchantPortalUtil"

export const COPYRIGHT_REGION_TEXTS = {
  EU: `Klarna Bank AB (publ)`,
  US: `Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215`,
  AP: `Klarna Australia Pty Ltd, ABN # 82 635 912 579`
}

/**
 * Gets full copyright text for the supplied region.
 * Falls back to EU region if no region is supplied.
 */
export const getCopyrightTextByRegion = (region = 'EU') => {
  const currentYear = new Date().getFullYear()
  return `Copyright © 2005-${currentYear} ${COPYRIGHT_REGION_TEXTS[region]}. All rights reserved.`
}

/**
 * Gets full copyright text for the region idenfied by the current location
 */
export const getCopyrightTextByLocation = () => {
  const merchantPortalRegion = findMerchantPortalRegion(window.location.href)
  return getCopyrightTextByRegion(merchantPortalRegion)
}
