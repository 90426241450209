import { Badge, DesignTokens, Typography } from '@klarna/bubble-ui'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

const BorderElement = styled.div(({ bgColorToken }) => {
  const bubbleDesignContext = DesignTokens.useDesignContext()
  return css`
    border-width: 10px;
    border-color: ${bubbleDesignContext.get(bgColorToken).toString()};
    border-style: solid;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: fixed;
    top: 0px;
    z-index: 110;
  `
})

const LabelElement = styled.div`
  cursor: default;
  display: flex;
  top: 0px;
  left: calc(50% - 50px);
  position: fixed;
  justify-content: center;
  width: 100px;
  z-index: 111;
`

const COLOR_TOKENS = {
  BLUE: 'colors/backgrounds/accent-inline',
  GREEN: 'colors/backgrounds/success-inline',
  YELLOW: 'colors/backgrounds/warning-inline',
  RED: 'colors/backgrounds/error-inline'
}

const ENV_CONFIG = {
  localhost: { label:  'Localhost', colorToken: COLOR_TOKENS.RED },
  staging: { label:  'Staging', colorToken: COLOR_TOKENS.GREEN },
  playground: { label:  'Playground', colorToken: COLOR_TOKENS.YELLOW },
  production: undefined
}

const EnvironmentBanner = ({ environment, hidden }) => {
  const config = ENV_CONFIG[environment] || ENV_CONFIG.default
  if (hidden || !config) {
    return null
  }

  return (
    <div data-testid="environment-banner" data-env={environment}>
      <BorderElement bgColorToken={config.colorToken} />
      <LabelElement>
        <Badge
          backgroundColorToken={config.colorToken}
          borderColorToken={config.colorToken}
        >
          <Typography textToken='text-style/text/labels/default/regular'>
            {config.label} environment
          </Typography>
        </Badge>
      </LabelElement>
    </div>
  )
}

EnvironmentBanner.propTypes = {
  hidden: PropTypes.bool,
  environment: PropTypes.oneOf(Object.keys(ENV_CONFIG))
}

EnvironmentBanner.defaultProps = {
  environment: 'production'
}

export default EnvironmentBanner
