const trustedSocialURLs = ['/auth/realms/merchants/broker/Google/login']

export function isTrustedURL(socialLoginUrl) {
  return socialLoginUrl !== undefined && trustedSocialURLs.some(rootURL => socialLoginUrl.startsWith(rootURL))
}

export function stringToHash(value) {
  return value
    ? value.split('').reduce((hash, char) => {
      return char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash;
    }, 0)
    : null;
}
