import * as bubble from '@klarna/bubble-ui'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { connect } from 'react-redux'

const getDefaultStyleSheet = () => ({
  messageContainer: {
    backgroundColor: 'white',
    maxWidth: 480,
    width: '100%',
    borderRadius: 16,
    addingRight: 10,
    paddingLeft: 10
  },
  summaryWrapper: {
    display: 'flex',
    alignItems: 'center'
  }
})

const MessageSummary = ({ summary, icon, styles }) => {
  return icon
    ? (
      <bubble.ParagraphSecondary error>
        <span style={styles}>
          <span style={{ display: 'flex' }}>{icon}</span>
          <span
            style={{ marginLeft: bubble.constants.Grid.SIZE * 4 }}
            dangerouslySetInnerHTML={{ __html: summary }}
          />
        </span>
      </bubble.ParagraphSecondary>
    )
    : (
      <bubble.ParagraphSecondary>
        <span dangerouslySetInnerHTML={{ __html: summary }} />
      </bubble.ParagraphSecondary>
    )
}

MessageSummary.propTypes = {
  summary: PropTypes.string,
  icon: PropTypes.node,
  styles: PropTypes.object
}

const Message = ({ type, summary, message }) => {
  const styleSheet = getDefaultStyleSheet()
  if (type === '2fa') {
    const locCandidate = window.location.href.match(/(eu|oc|us)/)
    const guidebookLoc = locCandidate ? locCandidate[0] : 'eu'
    return (
      <>
        <div style={styleSheet.messageContainer}>
          <bubble.AlertBlockWarning
            title={message.addingTwoFactor}
            content={[message.makeAccountMoreSecure]}
            linkLabel={message.learnMore}
            onLinkClick={() => {
              window.open(`https://${guidebookLoc}.portal.klarna.com/guidebook/two-factor-authentication`)
            }}
            data-testid='2fa'
          />
        </div>
        <bubble.SpacerVertical extraSmall />
      </>
    )
  } else {
    const icon = (['warning', 'error'].includes(type))
      ? <bubble.IconWarning color='black' />
      : null
    return summary
      ? (
        <>
          <div className='non-2fa-class' style={styleSheet.messageContainer}>
            <bubble.BlockPlain data-testid='not-2fa'>
              <MessageSummary summary={summary} icon={icon} styles={styleSheet.summaryWrapper} />
            </bubble.BlockPlain>
          </div>
          <bubble.SpacerVertical extraSmall />
        </>
      )
      : null
  }
}

Message.propTypes = {
  type: PropTypes.oneOf(['2fa', 'error', 'warning', 'success']),
  summary: PropTypes.string,
  message: PropTypes.shape({
    addingTwoFactor: PropTypes.string,
    makeAccountMoreSecure: PropTypes.string,
    learnMore: PropTypes.string
  })
}

export default R.compose(
  connect(state => ({
    message: state.message,
  })
  ))(Message)

export {Message}
