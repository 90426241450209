import { DesignTokens } from '@klarna/bubble-ui'
import { useMemo } from 'react'

const COLOR_TOKENS = {
  bgSubtleInline: 'colors/backgrounds/subtle-inline'
}

/**
 * Hook for re-using common colors and tokens
 */
const useDesignContext = () => {
  const bubbleDesignContext = DesignTokens.useDesignContext()

  return useMemo(() => ({
    designContext: bubbleDesignContext,
    pageBackgroundColor: bubbleDesignContext.get(COLOR_TOKENS.bgSubtleInline).toString()
  }), [bubbleDesignContext])

}

export default useDesignContext
