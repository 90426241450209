import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';


const RecaptchaV2 = ({recaptchaV2SiteKey,recaptchaV2Required}, ref) => {

  useEffect(() => {
    if (recaptchaV2Required) {
      window.recaptchaOptions = {
        enterprise: true
      }
    }
  }, [recaptchaV2Required])

  return (
    <>
      {recaptchaV2Required &&
        <ReCAPTCHA
          sitekey={recaptchaV2SiteKey}
          size='invisible'
          ref={ref}
          data-testid='recaptchav2'
        />}
    </>
  )
}
RecaptchaV2.propTypes = {
  recaptchaV2SiteKey: PropTypes.string,
  recaptchaV2Required: PropTypes.bool,
  ref: PropTypes.object
}

export default React.forwardRef(RecaptchaV2);
