import FingerprintJS from '@fingerprintjs/fingerprintjs'
import * as bubble from '@klarna/bubble-ui'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import TagManager from 'react-gtm-module'
import { connect } from 'react-redux'

import Google from '../../components/Icons/Icon'
import RecaptchaV2 from "../../components/RecaptchaV2";
import RecaptchaV3 from "../../components/RecaptchaV3";
import { isTrustedURL } from '../../shared/common'
import { findMerchantPortalUrl } from '../../shared/merchantPortalUtil'
import { recaptchaSubmit } from "../../shared/recaptchaUtil";

const Login = ({ loginAction, loginResetCredentialsUrl, username, recaptchaV2SiteKey, recaptchaV2Required, recaptchaV3Required, isTwoFaBypassEnabled, isGoogleSSOEnabled, message, social }) => {
  const [emailLabel, setEmailLabel] = useState(message.email)
  const [emailValue, setEmailValue] = useState(username)
  const [passwordVisible, setPasswordVisible] = useState(false);
  const passwordFieldType = passwordVisible ? 'text' : 'password';
  const [passwordLabel, setPasswordLabel] = useState(message.password)
  const [passwordValue, setPasswordValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef(null)
  const [captchaToken, setCaptchaToken] = useState('')
  const [action] = useState('login')
  const { executeRecaptcha } = useGoogleReCaptcha()
  const recaptchaRef = useRef(null)
  const [environment = 'production'] = (window.location.href.match(/(staging|playground)/) || [])
  const [fpHash, setFpHash] = useState('')
  const [showGoogleSSOButton, setShowGoogleSSOButton] = useState(isGoogleSSOEnabled)

  const handleFormSubmit = useCallback(async e => {
    e.preventDefault()
    setIsLoading(true)
    await recaptchaSubmit(recaptchaV2Required, recaptchaRef, recaptchaV3Required, action, setCaptchaToken, executeRecaptcha)

    formRef.current.submit()
  }, [action, executeRecaptcha,recaptchaV2Required,recaptchaV3Required,recaptchaRef])

  const isCapsLock = ev => {
    const charCode = ev.charCode
    const isShiftOn = ev.shiftKey
    return (charCode >= 97 && charCode <= 122 && isShiftOn) || (charCode >= 65 && charCode <= 90 && !isShiftOn)
  }
  // tag manager for cookie consent popup
  useEffect(() => {
    if (recaptchaV2Required || recaptchaV3Required) {
      const tagManagerArgs = {
        gtmId: 'GTM-PTZGRD6',
        dataLayer: {
          event: 'OneTrustEnabledKeycloak',
          environment
        }
      }
      TagManager.initialize(tagManagerArgs)
    }
  }, [environment, recaptchaV2Required, recaptchaV3Required])

  useEffect(() => {
    if (window && window.sessionStorage) {
      window.sessionStorage.removeItem('totpSecret')
      window.sessionStorage.removeItem('totpSecretEncoded')
      window.sessionStorage.removeItem('totpSecretQrCode')
    }
  }, [])
  useEffect( () => {
    if(isTwoFaBypassEnabled) {
      const setFp = async () => {
        const fp = await FingerprintJS.load()

        const { visitorId } = await fp.get()

        setFpHash(visitorId)
      }
      setFp()
    }
  }, [isTwoFaBypassEnabled])
  // check for ios webview
  useEffect(() => {
    const standalone = window.navigator.standalone
    const userAgent = window.navigator.userAgent.toLowerCase()
    const safari = /safari/.test(userAgent)
    const ios = /iphone|ipod|ipad/.test(userAgent)
    if (ios && !standalone && !safari) {
      setShowGoogleSSOButton(false)
    }
  }, [])

  return (
    <>
      <bubble.TitlePrimary correctFootprint small centered>{message.doLogIn}</bubble.TitlePrimary>
      <bubble.SpacerVertical medium />
      <form
        id='login'
        name='login'
        className='klarna-auth__fields'
        noValidate='noValidate'
        method='post'
        action={loginAction}
        autoComplete='on'
        onSubmit={handleFormSubmit}
        ref={formRef}
      >
        <bubble.Field
          type='email'
          id='username'
          name='username'
          autoFocus={emailValue.length === 0}
          required
          label={emailLabel}
          value={emailValue}
          onChange={setEmailValue}
          onBlur={() => setEmailLabel((emailValue.length === 0) ? message.email : message.emailWarning)}
          icon={<bubble.IconEnvelope />}
          disabled={social.usernameEditDisabled === 'true'}
        />
        <bubble.SpacerVertical small/>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          <div style={{flex: 1}}>
            <bubble.Field
              type={passwordFieldType}
              id='password'
              name='password'
              autoFocus={emailValue.length > 0}
              required
              label={passwordLabel}
              value={passwordValue}
              onChange={setPasswordValue}
              onKeyPress={ev => {
                if (isCapsLock(ev)) {
                  setPasswordLabel(message.passwordCaps)
                } else {
                  setPasswordLabel(message.password)
                }
              }}
              onBlur={() => setPasswordLabel(message.password)}
              icon={<bubble.IconPadlock/>}
            />
          </div>
          <div style={{position: 'absolute', right: 16, zIndex: 10}}>
            <bubble.TouchableIcon
              data-testid='reveal-icon'
              colorToken="colors/text/neutral"
              Icon={passwordVisible ? bubble.IconExclude : bubble.IconEye}
              onClick={() => setPasswordVisible(!passwordVisible)}
            />
          </div>
        </div>
        {isTwoFaBypassEnabled &&
        <input id='devicefp' name='devicefp' value={fpHash} data-testid='devicefp' type='hidden' />}
        <bubble.SpacerVertical small />
        <div style={{ textAlign: 'center' }}>
          <bubble.LinkLegal href={loginResetCredentialsUrl}>{message.emailForgotTitle}</bubble.LinkLegal>
        </div>
        <bubble.SpacerVertical medium />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <bubble.ButtonPrimary
            id='loginBtn'
            loading={isLoading}
          >
            {message.doLogIn}
          </bubble.ButtonPrimary>
        </div>
        <bubble.SpacerVertical small />

        {showGoogleSSOButton && social.loginUrl !== undefined && social.loginUrl &&
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}>
            <div style={{ width: '46%' }}> <bubble.SeparatorSecondary /> </div>
            <bubble.ParagraphTertiary centered light overflowEllipsisWidth={40}>{message.or}</bubble.ParagraphTertiary>
            <div style={{ width: '46%' }}> <bubble.SeparatorSecondary /> </div>
          </div>}
        <bubble.SpacerVertical small />
        <div id='kc-social-providers' style={{ display: 'flex', flexDirection: 'column' }}>
          {showGoogleSSOButton && social.loginUrl !== undefined && social.loginUrl
            .map((socialLoginUrl, index) => (
              <div key={socialLoginUrl} style={{ display: 'flex', flexDirection: 'column' }}>
                <bubble.ButtonSecondary
                  icon={Google}
                  id={`zocial-${social.alias[index]}`}
                  className={`zocial ${social.providerId[index]}`}
                  onClick={(e) => {
                    e.preventDefault()
                    if (isTrustedURL(socialLoginUrl)) {
                      location.href = socialLoginUrl
                    } else {
                      throw new Error('Not supported')
                    }
                  }}
                >
                  {message.loginWith} {social.displayName[index]}
                </bubble.ButtonSecondary>
                <bubble.SpacerVertical small />
              </div>
            ))}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <bubble.Typography align='center' colorToken='colors/text/neutral' textToken='text-style/text/super-paragraphs/tiny/regular'>
            {message.byLoggingIn}{' '}
            <bubble.Link
              align='center'
              colorToken='colors/text/default'
              isUnderlined
              href={`${findMerchantPortalUrl(window.location.href)}/privacy-policy`}
              rel='noopener noreferrer'
              target='_blank'
              textToken='text-style/text/super-paragraphs/tiny/regular'
            >
              {message.privacyNotice}.
            </bubble.Link>{' '}
          </bubble.Typography>
        </div>
        <RecaptchaV2
          ref={recaptchaRef}
          recaptchaV2SiteKey={recaptchaV2SiteKey}
          recaptchaV2Required={recaptchaV2Required}
        />
        <RecaptchaV3 recaptchaV3Required={recaptchaV3Required} captchaToken={captchaToken} />
      </form>
    </>
  )
}

Login.propTypes = {
  loginAction: PropTypes.string.isRequired,
  loginResetCredentialsUrl: PropTypes.string.isRequired,
  username: PropTypes.string,
  recaptchaV2SiteKey: PropTypes.string,
  recaptchaV2Required: PropTypes.bool,
  recaptchaV3Required: PropTypes.bool,
  isTwoFaBypassEnabled: PropTypes.bool,
  isGoogleSSOEnabled: PropTypes.bool,
  message: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    emailForgotTitle: PropTypes.string,
    byLoggingIn: PropTypes.string,
    privacyNotice: PropTypes.string,
    doLogIn: PropTypes.string,
    passwordCaps: PropTypes.string,
    emailWarning: PropTypes.string,
    loginWith: PropTypes.string,
    or: PropTypes.string
  }),
  social: PropTypes.shape({
    loginUrl: PropTypes.arrayOf(PropTypes.string),
    alias: PropTypes.arrayOf(PropTypes.string),
    displayName: PropTypes.arrayOf(PropTypes.string),
    providerId: PropTypes.arrayOf(PropTypes.string),
    usernameEditDisabled: PropTypes.string
  })
}

Login.defaultProps = {
  username: ''
}
export default connect(
  state => ({
    loginAction: state.url.loginAction,
    loginResetCredentialsUrl: state.url.loginResetCredentialsUrl,
    username: state.login.username,
    message: state.message,
    social: state.social,
    recaptchaV2SiteKey: state.recaptcha.recaptchaV2SiteKey,
    recaptchaV2Required: state.recaptcha.recaptchaV2Required,
    recaptchaV3Required: state.recaptcha.recaptchaV3Required,
    isTwoFaBypassEnabled: state.login.isTwoFaBypassEnabled,
    isGoogleSSOEnabled: state.login.isGoogleSSOEnabled
  })
)(Login)

export { Login }
