const initialState = {
  manualUrl: undefined,
  qrUrl: undefined,
  totpSecretEncoded: undefined,
  totpSecretQrCode: undefined,
  totpSecret: undefined,
  policyPeriod: undefined,
  policyInitialCounter: undefined,
  policyDigits: undefined,
  mode: undefined
}

export default (state = initialState) => state
