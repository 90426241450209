import * as bubble from '@klarna/bubble-ui';
import PropTypes from 'prop-types';
import React from 'react';


const RecaptchaFooter = ({message, recaptchaV2Required, recaptchaV3Required}) => {

  return (recaptchaV3Required || recaptchaV2Required) &&
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}
         data-testid='recaptcha-footer'>
      <bubble.Typography  align='center'
                          accessibilityPreset='paragraph'
                          textToken='text-style/text/paragraphs/tiny/regular'>
        {message.recaptchaFooter}{' '}
        <bubble.Link
          align='center'
          colorToken='colors/text/default'
          textToken='text-style/text/super-paragraphs/tiny/regular'
          isUnderlined
          href='https://policies.google.com/privacy'
          rel='noopener noreferrer'
          target='_blank'
        >
          {message.privacyPolicy}
        </bubble.Link>
        {' '}{message.and}{' '}
        <bubble.Link
          align='center'
          colorToken='colors/text/default'
          textToken='text-style/text/super-paragraphs/tiny/regular'
          isUnderlined
          href='https://policies.google.com/terms'
          rel='noopener noreferrer'
          target='_blank'
        >
          {message.termsOfService}
        </bubble.Link>
        {' '}{message.apply}.
      </bubble.Typography>
    </div>
}

RecaptchaFooter.propTypes = {
  recaptchaV3Required: PropTypes.bool,
  recaptchaV2Required: PropTypes.bool,
  message: PropTypes.shape({
    recaptchaFooter: PropTypes.string,
    termsOfService: PropTypes.string,
    privacyPolicy: PropTypes.string,
    apply: PropTypes.string,
    and: PropTypes.string
  })
}

RecaptchaFooter.defaultProps = {
  recaptchaV3Required: false,
  recaptchaV2Required: false
}

export default RecaptchaFooter
