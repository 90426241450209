import { createStore } from 'redux'

import reducer from './reducers'

export default (intialState = {}) => {
  return createStore(reducer, {
    ...window.state,
    ...intialState
  })
}
