import { DesignTokens, KlarnaLogoFull } from '@klarna/bubble-ui'
import React from 'react'
import styled, { css } from 'styled-components'

const HeaderElement = styled.header(() => {
  const designContext = DesignTokens.useDesignContext()
  const backgroundColor = designContext.get('colors/backgrounds/subtle-inline').toString()
  const leftPadding = designContext.get('space.300').value()

  return css`
    background-color: ${backgroundColor};
    padding-left: ${leftPadding}px;
    height: 60px;
    display: flex;
    align-items: center;
  `
})

const Header = () => {
  return (
    <HeaderElement>
      <KlarnaLogoFull />
    </HeaderElement>
  )
}

export default Header
