import React from 'react'
import { Provider } from 'react-redux'

import configureStore from '../../configureStore'
import Page from '../Page'
import Login from './Login'

const store = configureStore()

export default () => {
  return (
    <Provider store={store}>
      <Page isLoginPage>
        <Login />
      </Page>
    </Provider>
  )
}
