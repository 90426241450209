import PropTypes from 'prop-types';
import React from 'react';

const RecaptchaV3 = ({recaptchaV3Required,captchaToken}) => {
  return recaptchaV3Required &&
    <input id='captcha-token' name='captcha-token' value={captchaToken} type='hidden' data-testid='recaptchav3'/>
}
RecaptchaV3.propTypes = {
  recaptchaV3Required: PropTypes.bool,
  captchaToken: PropTypes.string
}

RecaptchaV3.defaultProps = {
  recaptchaV3Required: false,
  captchaToken: ''
}

export default RecaptchaV3
