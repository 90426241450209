const localPattern = /(http:\/\/(localhost:|keycloak:)([^/]+)).*/
const stagingPattern = /(https?):\/\/keycloak\.([^/]+).*/
const livePattern = /(https?):\/\/auth\.([^/]+).*/

const findMerchantPortalUrl = keycloakUrl => {
  if (typeof keycloakUrl !== 'string') {
    return ''
  }
  if (stagingPattern.test(keycloakUrl)) {
    const [protocol] = stagingPattern.exec(keycloakUrl).slice(1)
    return `${protocol}://portal.nonprod.klarna.net`
  } else if (livePattern.test(keycloakUrl)) {
    const [protocol, location] = livePattern.exec(keycloakUrl).slice(1)
    if (location.startsWith('playground')) {
      return `${protocol}://portal.playground.klarna.com`
    }
    return `${protocol}://portal.klarna.com`
  } else if (localPattern.test(keycloakUrl)) {
    return 'http://localhost:8888'
  } else {
    return ''
  }
}

const findMerchantPortalRegion = keycloakUrl => {
  if (stagingPattern.test(keycloakUrl)) {
    const [domain] = stagingPattern.exec(keycloakUrl).slice(2)
    return getRegionFromDomain(domain, false)
  } else if (livePattern.test(keycloakUrl)) {
    const [domain] = livePattern.exec(keycloakUrl).slice(2)
    if (domain.startsWith('playground')) {
      return getRegionFromDomain(domain, false)
    }
    return getRegionFromDomain(domain, true)
  } else if (localPattern.test(keycloakUrl)) {
    const [port] = localPattern.exec(keycloakUrl).slice(3)
    return getRegionFromPort(port)
  } else {
    return ''
  }
}

const getRegionFromDomain = (domain, isProd) => {
  const region = isProd ? domain.split(".",1)[0] : domain.split(".",2)[1]
  if (/^(eu|eu1)$/i.test(region)) {
    return 'EU'
  } else if (/^(us|us1)$/i.test(region)) {
    return 'US'
  } else if (/^(ap|ap1|oc)$/i.test(region)) {
    return 'AP'
  } else {
    return 'EU'
  }
}

const getRegionFromPort = (port) => {
  if (port === '8082') {
    return 'US'
  } else if (port === '8084') {
    return 'AP'
  } else {
    return 'EU'
  }
}

export {
  findMerchantPortalUrl,
  findMerchantPortalRegion
}
